import React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { Container } from '@ttstr/components';

const PreFooter: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Row className="my-4 d-flex align-items-center">
        <Col className="align-items-center text-right copyright">
          <span className="mb-0 lead mr-3">{t(`PREFOOTER.TEXT`)}</span>
          <a href="https://instagram.com/reaperentertainment/" className="mr-2 badge badge-warning" target="blank">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://facebook.com/ReaperEntertainment" className="mr-2 badge badge-warning" target="blank">
            <i className="fab fa-facebook"></i>
          </a>
          <a href="https://twitter.com/reaperentertain" className="mr-2 badge badge-warning" target="blank">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://youtube.com/c/ReaperEntertainmentEurope" className="mr-2 badge badge-warning" target="blank">
            <i className="fab fa-youtube"></i>
          </a>
          <a
            href="https://open.spotify.com/playlist/0mrDG0TyVU5VAKn8Qsqgyg?si=38ddd8a8b6de43e1&nd=1"
            className="badge badge-warning"
            target="blank"
          >
            <i className="fab fa-spotify"></i>
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default React.memo(PreFooter);
