import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { i18nextFormatter } from '@ttstr/components/Intl/IntlContext';
import LazyLanguages from '@ttstr/utils/LazyLanguages';

import shopConfig from '../shop-config.json';

i18n
  .use(LazyLanguages)
  // detects the language the browser is set to and tries to apply it
  // .use(detector)
  .use(initReactI18next);

i18n.init({
  debug: false, // process.env.NODE_ENV === 'development'
  // if the detected language isn't supported, fallback to default
  // fallbackLng: shopConfig.defaultLocale,
  fallbackLng: false,
  lng: shopConfig.defaultLocale,
  defaultNS: 'overrides',
  fallbackNS: ['overrides', 'commons'],
  load: 'languageOnly',
  ns: ['overrides', 'commons'],
  interpolation: {
    escapeValue: false, // not needed for react!!
    format: i18nextFormatter,
  },
  backend: {
    de: {
      commons: () => import('@ttstr/locales/de/commons.json'),
      overrides: () => import('../public/locales/de/overrides.json'),
    },
    en: {
      commons: () => import('@ttstr/locales/en/commons.json'),
      overrides: () => import('../public/locales/en/overrides.json'),
    },
  },
  react: {
    wait: true,
  },
});

export default i18n;
