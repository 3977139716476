import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { Product } from '@ttstr/api/products';
import {
  BrandPageLinks,
  Container,
  NativeSlider,
  VariantChooser,
  useShopConfig,
  ContentrArea,
  useProductDetailLink,
  ProductMarkup,
} from '@ttstr/components';
import MediaLinksComponent from '@ttstr/components/MediaLinks/MediaLinksComponent';

interface OwnProps {
  readonly product: Product;
}

type Props = Readonly<OwnProps>;

const ProductDetail: React.FC<Props> = ({ product }) => {
  const { t } = useTranslation();
  const { canonicalUrl } = useShopConfig();
  const productDetailLink = useProductDetailLink();

  const images = React.useMemo(() => [product.hero_image.url, ...product.gallery_image_urls.map((i) => i.original)], [
    product,
  ]);
  const thumbs = React.useMemo(
    () => [product.hero_image.thumb.url, ...product.gallery_image_urls.map((i) => i.thumb)],
    [product]
  );

  return (
    <article className="product-detail mt-md-5" itemScope itemType="http://schema.org/Product">
      <Helmet>
        {/* <title>
          {product.supertitle && product.supertitle + ' - '}
          {product.title}
          {product.subtitle && ' - ' + product.subtitle}
        </title>
        <link rel="canonical" href={productDetailLink(product, { canonicalUrl })} /> */}
        <body
          className={`product-detail-page inverse-navbar ${product.category_ids
            .map((id) => 'category-id-' + id)
            .toString()
            .replace(/,/g, ' ')} ${product.tag_ids
            .map((id) => 'tag-id-' + id)
            .toString()
            .replace(/,/g, ' ')}`}
        />
      </Helmet>
      <ProductMarkup product={product} />
      <meta itemProp="sku" content={String(product.id)} />

      <section className="product-content">
        <Container className="detail-page">
          <Row>
            <Col lg="6">
              <NativeSlider
                images={images.length ? images : [product.hero_image.url]}
                thumbs={thumbs.length ? thumbs : [product.hero_image.thumb.url]}
              />
            </Col>
            <Col lg="6" className="text-center text-lg-left">
              <header>
                <h1 className="product-header-title">
                  <span itemProp="name">
                    {product.supertitle && <small className="super-title text-muted">{product.supertitle}</small>}
                    {product.title}
                    <small className="sub-title">{product.subtitle}</small>
                  </span>
                </h1>
              </header>
              <ContentrArea id="before-variants" />
              <VariantChooser className="mb-4" product={product} showFormerPrice />
              <ContentrArea id="after-variants" />
              {product.description && (
                <>
                  <h2 className="smaller">{t(`PRODUCT.INFORMATION`)}</h2>
                  <div dangerouslySetInnerHTML={{ __html: product.description }} itemProp="description" />
                </>
              )}
              <MediaLinksComponent
                productMediaLinks={product.media_links}
                allowFullScreenYTVideos
                // hideYTControls
                containerClassName="embed-responsive-16by9 mb-3"
              />
              <BrandPageLinks brandIds={product.brand_ids} />
              <small className="d-block text-muted mt-2">{t(`PRODUCT.EXCLVAT`)}</small>
              <ContentrArea id="general-info" />
            </Col>
          </Row>
        </Container>
      </section>
    </article>
  );
};

export default React.memo(ProductDetail);
