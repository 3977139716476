import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';

import { Article } from '@ttstr/api';
import { receiveProducts } from '@ttstr/actions';
import { Container, LoadingSpinner, Maki2020, Masonry, useIntl, ContentrArea, NativeSlider } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

type Props = {
  /**
   * productFilter can be used if you want a Route to a dedicated page for special merch/products.
   *
   * Example:
   * You want a page only with merch that has the tag id 1234.
   * The function for productFilter would then be
   *  p => p.tag_ids.includes(1234)
   *
   * Then create a route in App.tsx with
   * <Route exact path="/specialmerch" render={()=> <ProductListing productFilter={p => p.tag_ids.includes(1234)} />} />
   */
  productFilter?: (p: Article) => any;
};

const ProductListing: React.FC<Props> = ({ productFilter = (p) => p }) => {
  const { t } = useTranslation();
  const { loading, products } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    const abortController = new AbortController();
    receiveProducts({ type: ['Product', 'Package', 'Ticket'] }, { signal: abortController.signal });
    return () => abortController.abort();
  }, [language]);

  const GOLIVE = new Date('2023-09-15T00:00:00+0200');
  const DEADLINE = new Date('2023-03-19T23:59:00+0100');

  return (
    <article>
      <Helmet>
        <title>{t(`PRODUCTS.TITLE`)}</title>
        <body className="product-listing-page inverse-navbar" />
      </Helmet>
      <div className="headerimage blurry-background-image d-flex justify-content-center align-items-center">
        <div className="headerimage-blur d-flex justify-content-center align-items-center">
          <Container>
            <NativeSlider
              className="mb-0 pt-md-5 pb-md-5"
              images={[
                require('./assets/images/header-himmelkraft.jpg'),
                require('./assets/images/end_of_green_header.jpg'),
                require('./assets/images/april_art_header.jpg'),
                require('./assets/images/robse-header.jpg'),
                require('./assets/images/metal-church-header.jpg'),
                require('./assets/images/skyeye-header.jpg'),
                require('./assets/images/tankard-header.jpg'),
                require('./assets/images/header-reaper.png'),
              ]}
              links={['', 'brand/4257', 'brand/4245', '/brand/4140', '/brand/3080', '/brand/3542', '/brand/3077', '']}
              interval={5000}
            />
          </Container>
        </div>
      </div>
      <Container className="list-page py-5">
        <ContentrArea id="before-listing" />

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : products.length === 0 ? ( // if no products
          <Alert color="info" className="text-center">
            {t(`MASONRY.NO_ITEMS`)}
          </Alert>
        ) : (
          // else show products
          <Masonry
            id="product-listing"
            type={Maki2020}
            products={products.filter(productFilter)}
            filters={['fulltext', 'brandId', 'categoryId', 'tagId']}
            showSubtitle
            showSupertitle
            showFormerPrice
          />
        )}

        <ContentrArea id="after-listing" />
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(ProductListing);
